<script>
export default {
  name: "ImplicitCallback",
  async beforeMount() {
    try {
      await this.$auth.handleAuthentication();
      this.$router.replace({
        path: this.$auth.getFromUri()
      });
    } catch (e) {
      console.log(e.message);
      var myMessage = "";
      if (e.message === "User is not assigned to the client application.") {
        myMessage = " Are you connecting with your PAA ?";
      }
      this.$router.replace({
        path: "/pages/403",
        query: { message: e.message + myMessage }
      });
    }
  },
  render(h) {
    return h();
  }
};
</script>